import create from 'zustand'
import { useHotspotStore } from '@/service/HotspotService'

export const useContentStore = create((set, get) => ({
  // non 60 fps data
  initialized: false,
  topview: { active: true },
  items: [],
  selectedHotspotId: 'topview',
  nearestHotspotId: null,
  hoveredHotspotId: null,

  // from cms
  loadingText: '',
  startPageSeoText: '',
  contentLayers: [],

  lazyInitFrom: experience => {
    if (get().initialized) return
    set({ initialized: true })

    const { loadingText, contentLayers, startPageSeoText } = experience
    set({ loadingText, contentLayers, startPageSeoText })

    // update current active content bt observing useHotspotStore's nearestHotspot and hoveredHotspot
    useHotspotStore.subscribe(() => {
      const { nearestHotspotId, hoveredHotspotId, selectedHotspotId } = get()
      const { nearestHotspot, hoveredHotspot } = useHotspotStore.getState()

      const newNearestHotspot = nearestHotspot ? nearestHotspot.id : null
      if (newNearestHotspot !== nearestHotspotId) {
        set({ nearestHotspotId: newNearestHotspot })
        if (newNearestHotspot !== selectedHotspotId) {
          get().selectHotspot(newNearestHotspot)
        }
      }

      const newHoveredHotspotId = hoveredHotspot ? hoveredHotspot.id : null
      if (newHoveredHotspotId !== hoveredHotspotId) {
        set({ hoveredHotspotId: newHoveredHotspotId })
      }
    })

    const topview = { active: true }
    const items = contentLayers.map(cl => {
      return { ...cl, itemId: cl.hotspot, active: false }
    })

    set({ items, topview })
  },

  selectTopView: () => {
    const { contentLayers, topview } = get()
    topview.active = true
    const items = contentLayers.map(cl => {
      return { ...cl, itemId: cl.hotspot, active: false }
    })
    set({ items, topview, selectedHotspotId: 'topview' })
  },

  selectHotspot: itemId => {
    const { contentLayers, topview } = get()
    topview.active = false
    const items = contentLayers.map(cl => {
      return { ...cl, itemId: cl.hotspot, active: cl.hotspot === itemId }
    })
    set({ items, topview, selectedHotspotId: itemId })
  },

  selectNextHotspot: () => {
    const { next } = get().getPrevNextItem()
    get().selectHotspot(next.itemId)
  },

  selectPrevHotspot: () => {
    const { prev } = get().getPrevNextItem()
    get().selectHotspot(prev.itemId)
  },

  getPrevNextItem: () => {
    const { items } = get()
    const activeIndex = items.findIndex(item => item.active)
    if (activeIndex === -1) {
      return { prev: items[items.length - 1], next: items[0] }
    }
    const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    return { prev: items[prevIndex], next: items[nextIndex] }
  },
}))
