import { ArrowButtonContainer } from './styles'
import ArrowLeft from './assets/arrowLeft.svg'

const ArrowButton = ({
  direction = 'left',
  clickHandler = () => {},
  hoverOverwrite = false,
  extraStyle,
  transparent = false,
}) => {
  return (
    <ArrowButtonContainer
      transparent={transparent}
      onClick={clickHandler}
      direction={direction}
      hoverOverwrite={hoverOverwrite}
      extraStyle={extraStyle}
    >
      <ArrowLeft />
    </ArrowButtonContainer>
  )
}

export default ArrowButton
