import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSceneStore } from '@/service/SceneService'
import { useContentStore } from '@/service/ContentService'
import LazyExperience from '@/components/Experience/LazyExperience'
import Intro from '@/components/Scene/Intro'

const ExperienceContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  user-select: none;
`

export default function Experience({ experience, experienceData }) {
  const [visible, setVisible] = useState(experience)
  const [wasVisible, setWasVisible] = useState(experience)
  const [hasPower, setHasPower] = useState(false)

  useEffect(() => {
    visible && setWasVisible(true)
  }, [visible])

  useEffect(() => {
    let id = null
    if (experience) {
      !visible && setVisible(true)
    } else {
      id = setTimeout(() => {
        visible !== experience && setVisible(experience)
      }, 3000)
    }
    return () => {
      clearTimeout(id)
    }
  }, [experience])

  useEffect(() => {
    if (experienceData) {
      useSceneStore.setState({ experienceData })
      useContentStore.getState().lazyInitFrom(experienceData.experience)
    }
  }, [experienceData])

  const loadingText = experienceData && experienceData.experience.loadingText

  const startPageSeoText = experienceData && experienceData.experience.startPageSeoText

  useEffect(() => {
    setTimeout(() => {
      setHasPower(true)
    }, 2500)
  })
  return (
    <>
      <ExperienceContainer>
        {hasPower && (visible || wasVisible) && <LazyExperience visible={visible} experience={experience} />}
      </ExperienceContainer>
      {experience && <Intro loadingText={loadingText} startPageSeoText={startPageSeoText} />}
    </>
  )
}
