import dynamic from 'next/dynamic'
import ErrorBoundary from '@/components/ErrorBoundary'
import { useSceneStore } from '@/service/SceneService'
const Scene = dynamic(() => import('@/components/Scene'), { ssr: false })
const QuickNavigation = dynamic(() => import('@/components/Experience/QuickNavigation'), { ssr: false })
const ContentOverlay = dynamic(() => import('@/components/Experience/ContentOverlay'), { ssr: false })
const Onboarding = dynamic(() => import('@/components/Experience/Onboarding'), { ssr: false })
const OverviewHeadline = dynamic(() => import('@/components/Experience/OverviewHeadline'), { ssr: false })
const Loader = dynamic(() => import('@/components/Scene/Loader'), { ssr: false })

export default function LazyExperience({ visible, experience }) {
  return (
    <ErrorBoundary
      onError={() => {
        const sceneError = 'Failed loading 3D Experience.'
        useSceneStore.setState({ sceneError })
      }}
    >
      <Scene visible={visible} />
      {visible && (
        <>
          <ContentOverlay />
          <QuickNavigation />
          <Onboarding />
          <OverviewHeadline />
        </>
      )}
      {!!experience && <Loader />}
    </ErrorBoundary>
  )
}
