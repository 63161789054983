import Head from 'next/head'
import { TagManager } from '@/utils/tracking/TagManager'

const HeadMeta = ({ globalSeo, pageSeo }) => {
  if (!globalSeo) return null
  const title = pageSeo && pageSeo.title ? pageSeo.title : globalSeo.title
  const description = pageSeo && pageSeo.description ? pageSeo.description : globalSeo.description
  const image = pageSeo && pageSeo.image ? pageSeo.image : globalSeo.image
  const smallImage = image && image.formats.small
  return (
    <Head>
        <script type="text/javascript" dangerouslySetInnerHTML={{
            __html: `// var et_pagename = ""; 
      // var et_areas = ""; 
      // var et_tval = 0; 
      // var et_tsale = 0; 
      // var et_tonr = ""; 
      // var et_basket = "";`
        }}/>
        <script id="_etLoader" type="text/javascript" charset="UTF-8" data-block-cookies="true" data-secure-code="ekmLVb" src="//code.etracker.com/code/e.js" async></script>

        <link rel="preconnect" href="//app.eu.usercentrics.eu"/>
        <link rel="preconnect" href="//api.eu.usercentrics.eu"/>
        <link rel="preconnect" href="//sdp.eu.usercentrics.eu"/>
        <link rel="preload" href="//app.eu.usercentrics.eu/browser-ui/latest/loader.js" as="script"/>
        <script id="usercentrics-cmp" src="https://app.usercentrics.eu/browser-ui/latest/loader.js" data-settings-id="RdrodjALHs7Ku-" async></script>

        <TagManager />

      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={globalSeo.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      {smallImage && (
        <>
          <meta property="og:image" content={smallImage.url} />
          <meta property="og:image:type" content={smallImage.mime} />
          <meta property="og:image:width" content={smallImage.width} />
          <meta property="og:image:height" content={smallImage.height} />
        </>
      )}

      <meta name="viewport" content="width=device-width, minimum-scale=1, initial-scale=1.0" />
      <meta name="theme-color" content="#000" />

      <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
      <link rel="apple-touch-icon" sizes="16x16" href="/icons/favicon-16x16.png" />
      <link rel="apple-touch-icon" sizes="32x32" href="/icons/favicon-32x32.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
      <link rel="manifest" href="/manifest.json" />
      {/* Don't have a proper mask */}
      {/* <link rel="mask-icon" color="#000000" href="/icons/safari-pinned-tab.svg" /> */}
      {/* We don't have a PWA splash screen */}
      {/* <link rel="apple-touch-startup-image" href="/startup.png" /> */}
      <link rel="shortcut icon" href="/icons/favicon.ico" />
      <link rel="stylesheet" href="/css/globalstyles.css"/>
    </Head>
  )
}

export default HeadMeta
