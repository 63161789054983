import { css, createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import { colors, theme } from '@/styles/color'
import { scrollbarStyle } from '@/styles/scroll'
import { trasandinaFontStyle } from '@/styles/font'
import { baseTextStyle } from '@/styles/text'

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  html {
    ${props =>
      props.experience
        ? css`
            position: fixed;
            width: 100%;
          `
        : ''}
    height: 100%;
    ${scrollbarStyle};
    ${props =>
      props.preventScroll &&
      css`
        touch-action: none;
      `}
  }
  body {
    ${trasandinaFontStyle}
    ${baseTextStyle}
    margin: 0;
    padding: 0;
    min-height: 100%;
    box-sizing: border-box;
    background-color: ${colors.blue.dark};
    color: ${theme.font};
    *::selection {
      color: ${theme.background};
      background: ${theme.interactive.highlight};
    }
    ${scrollbarStyle};
    -webkit-tap-highlight-color: ${colors.moss.light}22;
  }
`
