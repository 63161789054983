import styled, { css } from 'styled-components'
import { center, centerV, extraSmall, fullSize, fullSizeAndAbsolute, mediaMax, mediaMin, small } from '@/styles/layout'
import { colors } from '@/styles/color'
import easing from '@/styles/easing'
import { neueMachinaFontStyle } from '@/styles/font'

export const ButtonList = styled.div`
  position: absolute;
  ${center};
  perspective: 550px;
  perspective-origin: 50% 0;
  ${mediaMax(
    'small',
    css`
      left: 21px;
      ${centerV}
    `
  )};

  @media screen and (max-height: 460px) and (min-width: 500px) {
    left: 15vh;
    ${centerV}
  }
`

export const ButtonContainer = styled.div`
  transition: opacity 0.2s ${easing.outStrong}, transform 0.7s ${easing.outStrong};
  transition-delay: ${props => props.delayIndex * (0.3 / props.totalCount)}s;
  transform: translateZ(0);
  opacity: 1;
`

export const BackgroundContainer = styled.div`
  ${fullSizeAndAbsolute};
  transition: transform 1s ${easing.outStrong};
  transform: scale(1.4);
`

export const MenuButton = styled.a`
  display: block;
  font-size: min(${props => 150 / props.menuDimensions.columns}vw, ${props => 52 / props.menuDimensions.rows}vh);
  line-height: 1.2em;
  cursor: pointer;
  color: ${colors.moss.light};
  transition: color 0.2s, text-stroke-color 0.2s;
  text-decoration: none;
  white-space: nowrap;
  ${neueMachinaFontStyle};
  ${props =>
    props.deactivated &&
    css`
      pointer-events: none;
    `};
  @media (pointer: fine) {
    &:not(:hover) {
      color: ${colors.moss.base};
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #228178;
    }
  }
  ${small(
    css`
      font-size: min(${props => 150 / props.menuDimensions.columns}vw, ${props => 50 / props.menuDimensions.rows}vh);
    `
  )}
  ${extraSmall(
    css`
      font-size: min(${props => 135 / props.menuDimensions.columns}vw, ${props => 50 / props.menuDimensions.rows}vh);
    `
  )}
`

const margin = 30
const mobileMargin = 18

export const BottomRow = styled.div`
  position: absolute;
  height: 24px;
  bottom: ${margin}px;
  left: ${margin}px;
  right: ${margin}px;
  transition: transform 0.5s ${easing.outStrong};
  ${mediaMax(
    'small',
    css`
      bottom: ${mobileMargin}px;
      left: ${mobileMargin}px;
      right: ${mobileMargin}px;
    `
  )}
`

const sideColumnPadding = 36

export const CopyrightLabel = styled.span``

export const SideColumn = styled.div`
  position: absolute;
  left: ${margin + 24}px;
  bottom: ${margin}px;
  width: calc(100vh - ${2 * (margin + sideColumnPadding)}px);
  padding: 0 ${sideColumnPadding}px;
  transition: transform 0.5s ${easing.outStrong};
  transform-origin: left bottom;
  transform: rotate(-90deg);
  text-align: center;
  pointer-events: none;
  span,
  a {
    pointer-events: all;
    display: inline-block;
    margin: 0 2.5vh;
    color: ${colors.grey.base};
    @media screen and (max-height: 560px) {
      margin: 0 1vh;
      font-size: 14px;
    }
    @media screen and (max-height: 460px) {
      font-size: 12px;
    }
    @media screen and (max-height: 350px) {
      font-size: 10px;
    }
  }
  @media screen and (max-height: 350px) {
    ${CopyrightLabel} {
      display: none;
    }
  }

  ${mediaMax(
    'medium',
    css`
      left: auto;
      top: ${mobileMargin}px;
      bottom: auto;
      right: ${mobileMargin + 24}px;
      width: calc(100vh - ${2 * (mobileMargin + sideColumnPadding)}px);
      transform-origin: right top;
      transform: rotate(-90deg);
    `
  )}
`

export const MenuContainer = styled.div`
  position: fixed;
  ${fullSize};
  background-color: ${colors.blue.dark};
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 1;
  user-select: none;
  ${props =>
    props.open
      ? css`
          pointer-events: all;
          opacity: 1;
          ${BackgroundContainer} {
            transform: scale(1);
          }
        `
      : css`
          ${SideColumn} {
            transform: rotate(-90deg) translateY(-100px);
            ${mediaMax(
              'medium',
              css`
                transform: rotate(-90deg) translateY(100px);
              `
            )}
          }
          ${BottomRow} {
            transform: translateY(100px);
          }
          ${ButtonContainer} {
            transform: translateZ(min(10vh, 10vw));
            opacity: 0;
          }
        `};
`
