import ArrowIcon from '@/assets/icons/arrow.svg'
import { IconContainer } from './styles'
import { css } from 'styled-components'

const NextPrevButton = ({ next = false, prev = false, clickHandler, extraStyle = css`` }) => {
  return (
    <IconContainer next={next} prev={prev} onClick={clickHandler} extraStyle={extraStyle}>
      <ArrowIcon />
    </IconContainer>
  )
}

export default NextPrevButton
