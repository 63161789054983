import styled, { css } from 'styled-components'
import easing from '@/styles/easing'
import { mediaMax } from '@/styles/layout'
import { colors } from '@/styles/color'

const transitionStepDuration = 0.25

const lineStyle = css`
  height: 3px;
  background-color: ${colors.white};
  border-radius: 1px;
  position: relative;
  right: 0;
  transform-origin: 100% center;
  left: ${props => (props.short ? 33.3 : 0)}%;
  width: ${props => (props.short ? 66.6 : 100)}%;
  transition: opacity ${transitionStepDuration}s ${easing.inOutStrong};
`

export const Line = styled.div`
  ${lineStyle};
`

export const XLine = styled.div`
  ${lineStyle};
  transform-origin: center;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform ${transitionStepDuration}s ${easing.inOutStrong};
  transition-delay: 0s;
`

export const XLineContainer = styled.div`
  height: 3px;
  position: relative;
  transform-origin: 100% center;
`

export const BurgerContainer = styled.div`
  width: 62px;
  margin-right: ${props => props.headerGap - props.safeZone}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  position: relative;
  cursor: pointer;
  padding: ${props => props.safeZone}px;
  transition: width ${transitionStepDuration}s ${easing.inOutStrong};
  transition-delay: ${transitionStepDuration}s;
  ${props =>
    props.showX &&
    css`
      width: 25px;
      transition-delay: 0s;
      ${Line} {
        opacity: 0;
      }
      ${XLine} {
        transform: rotate(45deg);
        transition-delay: ${transitionStepDuration}s;
        &:nth-of-type(1) {
          transform: rotate(-45deg);
        }
      }
    `}
  ${mediaMax(
    'small',
    css`
      margin-right: 0;
      max-width: 62px;
      padding-right: 0;
    `
  )}
`
