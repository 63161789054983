import {
  BackgroundContainer,
  BottomRow,
  ButtonContainer,
  ButtonList,
  CopyrightLabel,
  MenuButton,
  MenuContainer,
  SideColumn,
} from './styles'
import { useMenuStore } from '@/service/MenuService'
import Header from '@/components/Header'
import SocialLinks from './SocialLinks'
import Linky, { getPageLikeFromLinkObject, makeRoot } from '@/components/common/Linky'
import BlurryBackgroundSimple from '@/components/common/BlurryBackgroundSimple'
import { useEffect } from 'react'
import { useTransitionStore } from '@/service/TransitionService'
import dynamic from 'next/dynamic'

// TODO change this to none dynamic import once en should be public
const LanguageSwitch = dynamic(() => import('@/components/Menu/LanguageSwitch'), { ssr: false })

const Menu = ({ page, menu = [] }) => {
  const { items, sideItems } = menu
  const open = useMenuStore(state => state.open)
  const { closeMenu } = useMenuStore()
  const transitionOngoing = useTransitionStore(state => state.transitionOngoing)
  const getTitleLengthFromMenuItem = menuItem => {
    const pageLike = menuItem.page
      ? menuItem.page
      : menuItem.location
      ? menuItem.location
      : menuItem.project
      ? menuItem.project
      : menuItem.job
      ? menuItem.job
      : null
    return pageLike && pageLike.shortTitle
      ? pageLike.shortTitle.length
      : pageLike && pageLike.title
      ? pageLike.title.length
      : menuItem.title
      ? menuItem.title.length
      : 0
  }

  useEffect(() => {
    window.addEventListener('popstate', closeMenu, false)
    return () => {
      window.removeEventListener('popstate', closeMenu, false)
    }
  }, [])

  const titleLengths = items.map(menuItem => getTitleLengthFromMenuItem(menuItem))
  const menuDimensions = { rows: items.length, columns: titleLengths.sort((a, b) => b - a)[0] }

  return (
    <MenuContainer open={open}>
      <BackgroundContainer>
        <BlurryBackgroundSimple />
      </BackgroundContainer>
      <Header page={page} hideBackButton visible={open} />
      <ButtonList>
        {items.map((item, index) => {
          return (
            <ButtonContainer key={index} delayIndex={index} totalCount={items.length}>
              {item['__component'] === 'menu.page-item' ? (
                <Linky to={item['page']}>
                  <MenuButton menuDimensions={menuDimensions} onClick={closeMenu} deactivated={transitionOngoing}>
                    {getPageLikeFromLinkObject(item).shortTitle
                      ? getPageLikeFromLinkObject(item).shortTitle
                      : getPageLikeFromLinkObject(item).title}
                  </MenuButton>
                </Linky>
              ) : item['__component'] === 'menu.experience-item' ? (
                <Linky to={makeRoot()}>
                  <MenuButton menuDimensions={menuDimensions} onClick={closeMenu}>
                    {item.title}
                  </MenuButton>
                </Linky>
              ) : null}
            </ButtonContainer>
          )
        })}
      </ButtonList>
      <BottomRow>
        <SocialLinks menu={menu} />
        <LanguageSwitch page={page} />
      </BottomRow>
      <SideColumn>
        <CopyrightLabel>© 2023 DES GmbH</CopyrightLabel>
        {sideItems.map((item, index) => (
          <Linky key={index} to={item.page}>
            <a onClick={closeMenu}>{getPageLikeFromLinkObject(item).title}</a>
          </Linky>
        ))}
      </SideColumn>
    </MenuContainer>
  )
}

export default Menu
