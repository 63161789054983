import { CloseButtonContainer, Line } from './styles'

const CloseButton = ({ clickHandler = () => {} }) => {
  return (
    <CloseButtonContainer onClick={clickHandler}>
      <Line />
      <Line />
    </CloseButtonContainer>
  )
}

export default CloseButton
