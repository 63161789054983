import { BackButtonColumn, HeaderContainer, headerGap, headerSafeZone, LogoColumn, LogoContainer } from './styles'
import DESLogo from '@/assets/des_logo.svg'
import BurgerButton from './BurgerButton'
import ArrowButtonWithText from '@/components/common/ArrowButtonWithText'
import Linky from '@/components/common/Linky'
import { useMenuStore } from '@/service/MenuService'

const Header = ({ page, hideBackButton = false, visible = true }) => {
  const toggleMenu = useMenuStore(state => state.toggleMenu)

  return (
    <HeaderContainer>
      <BackButtonColumn>
        {!hideBackButton && !!page && !!page.parent && (
          <Linky to={page.parent}>
            <a>
              <ArrowButtonWithText text={page.parent.shortTitle ? page.parent.shortTitle : page.parent.title} />
            </a>
          </Linky>
        )}
      </BackButtonColumn>
      <LogoColumn>
        <BurgerButton safeZone={headerSafeZone} headerGap={headerGap} active={visible} />
        <LogoContainer onClick={toggleMenu}>
          <DESLogo />
        </LogoContainer>
      </LogoColumn>
    </HeaderContainer>
  )
}

export default Header
