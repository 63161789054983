import styled, { css } from 'styled-components'
import { mediaMax, medium } from '@/styles/layout'
import { column } from '@/styles/grid'

const vPadding = 40
const vPaddingTablet = 40
const vPaddingMobile = 32
const headerHeight = 20
export const headerSafeZone = 10
export const headerGap = 22

export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: ${vPadding - headerSafeZone}px;
  user-select: none;

  ${medium(css`
    top: ${vPaddingTablet - headerSafeZone}px;
  `)}
  ${mediaMax(
    'small',
    css`
      top: ${vPaddingMobile - headerSafeZone}px;
    `
  )}
`

export const BackButtonColumn = styled.div`
  /*${column(5, 2)};*/
  position: absolute;
  padding-top: ${headerSafeZone}px;
  left: max(3vw, 32px);
  ${mediaMax(
    'small',
    css`
      padding-top: 100px;
      /* ${column(6, 1)};*/
    `
  )}
`

export const LogoColumn = styled.div`
  position: absolute;
  top: 0;
  right: max(3vw, 32px);
  transform: translateX(-${headerSafeZone}px);
  /*${column(2, 1)};*/
  height: ${headerHeight + headerSafeZone * 2}px;
  display: flex;
  flex-direction: row;
  user-select: none;
  ${medium(css`
    /* ${column(2, 0)};*/
  `)};
`

export const LogoContainer = styled.a`
  display: block;
  padding: ${headerSafeZone}px 0 ${headerSafeZone}px ${headerSafeZone}px;
  margin: 0 0 0 -${headerSafeZone}px;
  cursor: pointer;
  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )}
`
