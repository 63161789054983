import styled, { css, keyframes } from 'styled-components'
import { mediaMax, mediaMin } from '@/styles/layout'
import { TRANSITION, transitionInDurationMS, transitionOutDurationMS } from '@/service/TransitionService'

export const ContentContainer = styled.div`
  position: relative;
  padding-top: 75px;
  pointer-events: none;
  & > div {
    pointer-events: all;
  }
  ${mediaMin(
    'large',
    css`
      padding-top: 100px;
    `
  )}
  ${mediaMax(
    'small',
    css`
      padding-top: 140px;
    `
  )}
`

export const TransitionContainer = styled.div`
  transition: opacity
    ${props => (props.transition === TRANSITION.IN ? transitionInDurationMS : transitionOutDurationMS) / 1000}s;
  opacity: ${props => (props.transition === TRANSITION.IN ? 1 : 0)};
`
