import Experience from '@/components/Experience'
import { ContentContainer, TransitionContainer } from './styles'
import HeadMeta from './HeadMeta'
import Footer from '@/components/Footer'
import Menu from '@/components/Menu'
import Header from '@/components/Header'
import DroneButton from '@/components/DroneButton'
import { useTransitionStore } from '@/service/TransitionService'
import { useEffect } from 'react'
import MediaLayer from '@/components/MediaLayer'
import { useRouter } from 'next/router'

export default function SiteLayout({ children, experience, uniqueId, pageProps }) {
  const router = useRouter()

  const { pageData, experienceData } = pageProps
  const isPage = pageData && !!pageData.page
  const isExperience = experienceData && !!experienceData.experience
  const pageSeo = isPage && { ...pageData.page.seo, title: pageData.page.title }
  const experienceSeo = isExperience && { ...experienceData.experience.seo, title: experienceData.experience.title }
  const globalSeo = pageData?.seo
  const contentKey = uniqueId + '-' + router.locale + '-' + (isPage ? pageData.page.path + pageData.page.slug : null)
  const transition = useTransitionStore(state => state.transition)

  useEffect(() => {
    useTransitionStore.getState().transitionIn()
  }, [contentKey])

  return (
    <>
      <HeadMeta globalSeo={globalSeo} pageSeo={pageSeo || experienceSeo} title />
      <TransitionContainer transition={transition}>
        <Experience experience={experience} experienceData={experienceData} />
        <ContentContainer key={contentKey}>{children}</ContentContainer>
        {pageData && <Header page={pageData.page} />}
        {isPage && <DroneButton />}
        {isPage && <Footer footer={pageData.footer} />}
        <MediaLayer />
        {pageData && <Menu page={pageData.page} menu={pageData.menu} />}
      </TransitionContainer>
    </>
  )
}
