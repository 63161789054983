import { SocialLinksContainer, SocialLink } from './styles'
import LinkedinIcon from '@/assets/icons/social/linkedin.svg'
import InstagramIcon from '@/assets/icons/social/instagram.svg'
import FacebookIcon from '@/assets/icons/social/facebook.svg'
import KununuIcon from '@/assets/icons/social/kununu.svg'

const SocialLinks = ({ menu }) => {
  return (
    <SocialLinksContainer>
      <SocialLink rel="noreferrer" aria-label="LinkedIn" href={menu.socialLinkedin} target="_blank">
        <LinkedinIcon />
      </SocialLink>
      <SocialLink rel="noreferrer" aria-label="Kununu" href={menu.socialKununu} target="_blank">
        <KununuIcon />
      </SocialLink>
      <SocialLink rel="noreferrer" aria-label="Instagram" href={menu.socialInstagram} target="_blank">
        <InstagramIcon />
      </SocialLink>
      <SocialLink rel="noreferrer" aria-label="Facebook" href={menu.socialFacebook} target="_blank">
        <FacebookIcon />
      </SocialLink>
    </SocialLinksContainer>
  )
}

export default SocialLinks
