import styled, { keyframes, css } from 'styled-components'
import { colors, theme } from '@/styles/color'

const headBob = keyframes`
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
`

const width = 47
const height = 26

const hoverStyle = css`
  svg {
    [class*='head'] {
      animation: ${headBob} 0.5s ease forwards 0.25s;
    }
    [class*='line'] {
      stroke-dashoffset: 200%;
    }
  }
`

export const ArrowButtonContainer = styled.div`
  position: relative;
  background-color: ${props => (props.transparent ? 'transparent' : theme.interactive.highlight)};
  display: inline-block;
  line-height: 0;
  cursor: pointer;
  width: ${props => (props.direction === 'up' || props.direction === 'down' ? height : width)}px;
  height: ${props => (props.direction === 'up' || props.direction === 'down' ? width : height)}px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
      rotate(
        ${props =>
          props.direction === 'up' ? 90 : props.direction === 'down' ? -90 : props.direction === 'right' ? 180 : 0}deg
      );
    fill: ${colors.moss.base};
    stroke: ${colors.moss.base};
    [class*='line'] {
      stroke-width: 1;
      transition: stroke-dashoffset 0.35s ease;
      stroke-dasharray: 100%;
    }
  }
  ${props => props.hoverOverwrite && hoverStyle}
  &:hover {
    ${hoverStyle};
  }
  ${props => props.extraStyle};
`
