import styled, { css, keyframes } from 'styled-components'
import { colors } from '@/styles/color'
import { centerH, fullSizeAndAbsolute, mediaMax, mediaMin } from '@/styles/layout'
import { neueMachinaFontStyle } from '@/styles/font'
import easing from '@/styles/easing'

export const bigCircleSize = 89
export const smallCircleSize = 70

const hoverAnimation = keyframes`
  0% {
    transform: translate3d(0, 7.5%, 0) rotate(1deg);
  }
  100% {
    transform: translate3d(0, -7.5%, 0) rotate(-1deg);
  }
`

export const DroneImageContainer = styled.div`
  position: absolute;
  background-image: url(https://d17mhzg4etm7z2.cloudfront.net/website/drone.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 60%;
  top: 22%;
  right: -15%;
  animation: ${hoverAnimation} 3s ${easing.inOutStrong} alternate infinite;
  pointer-events: none;
`

export const DottedCircleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 14%;
  width: 75%;
  height: 75%;
  opacity: 0.7;
  transform: scale(0.95);
  transition: opacity 0.25s ${easing.outStrong}, transform 0.25s ${easing.outStrong};
  pointer-events: none;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const TextContainer = styled.div`
  position: absolute;
  text-transform: uppercase;
  font-size: 10px;
  display: inline-block;
  transform: translate3d(0, -50%, 0) rotate(-90deg);
  top: 50%;
  left: calc(-26px + 17%);
  white-space: nowrap;
  ${neueMachinaFontStyle};
  pointer-events: none;
  letter-spacing: 0.1em;
`

export const DroneButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;

  width: max(10vw, 100px);
  height: max(10vw, 100px);
  max-width: 160px;
  cursor: pointer;
  @media (pointer: fine) {
    &:hover {
      ${DottedCircleContainer} {
        opacity: 1;
        transform: scale(1.05) rotate(5deg);
      }
    }
  }
  ${mediaMax(
    'small',
    css`
      left: calc(max(3vw, 32px) - 5px);
    `
  )}
  ${mediaMin(
    'medium',
    css`
      ${centerH};
    `
  )};
`

export const ClickArea = styled.a`
  ${fullSizeAndAbsolute};
`

const pulsating = keyframes`
  from {
    transform: scale(0.65);
  }
  to {
    transform: scale(0.75);
  }
`

export const Glow = styled.div`
  ${fullSizeAndAbsolute};
  opacity: 0.25;
  background: radial-gradient(50% 50% at 50% 50%, ${colors.moss.light} 0%, ${colors.moss.light}00 100%);
  animation: ${pulsating} 2s ${easing.inOutStrong} infinite alternate;
`
