import styled from 'styled-components'
import { colors } from '@/styles/color'

const size = 28
const padding = 5
const sizePlusPadding = size + 2 * padding

export const CloseButtonContainer = styled.div`
  width: ${sizePlusPadding}px;
  height: ${sizePlusPadding}px;
  position: relative;
  cursor: pointer;
`

const lineStrength = 3

export const Line = styled.div`
  height: ${lineStrength}px;
  background-color: ${colors.white};
  border-radius: 1px;
  position: absolute;
  width: ${size}px;
  top: calc(50% - ${lineStrength / 2}px);
  left: ${padding}px;
  &:nth-of-type(1) {
    transform: rotate(45deg);
  }
  &:nth-of-type(2) {
    transform: rotate(-45deg);
  }
`
