import create from 'zustand'

export const useMenuStore = create((set, get) => ({
  open: false,
  toggleMenu: () => {
    set({ open: !get().open })
  },
  closeMenu: () => {
    set({ open: false })
  },
}))
