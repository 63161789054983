import {
  DroneButtonContainer,
  Glow,
  DroneImageContainer,
  DottedCircleContainer,
  ClickArea,
  TextContainer,
} from './styles'
import DottedCircle from './assets/dottedCircle.svg'
import Linky, { makeRoot } from '@/components/common/Linky'

const DroneButton = () => {
  return (
    <DroneButtonContainer>
      <Linky to={makeRoot()}>
        <ClickArea />
      </Linky>
      <TextContainer>Fly me</TextContainer>
      <DottedCircleContainer>
        <Glow />
        <DottedCircle />
      </DottedCircleContainer>
      <DroneImageContainer />
    </DroneButtonContainer>
  )
}

export default DroneButton
