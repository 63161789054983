import styled, { css } from 'styled-components'
import { center, centerH, fullSizeAndAbsolute } from '@/styles/layout'
import { colors } from '@/styles/color'
import easing from '@/styles/easing'

export const topBottomHeight = 72

export const topAndBottomStyle = css`
  height: ${topBottomHeight}px;
  transition: transform 0.5s ${easing.inOutStrong};
  position: relative;
  pointer-events: none;
`

export const Top = styled.div`
  ${topAndBottomStyle};
`

export const Bottom = styled.div`
  ${topAndBottomStyle};
`

export const PaginationContainer = styled.div`
  position: relative;
  top: -15px;
`

export const nextPrevButtonStyle = css`
  position: relative;
  top: 20px;
  margin: 0 12px;
`

export const Middle = styled.div`
  pointer-events: none;
  transition: transform 0.5s ${easing.inOutStrong}, opacity 0.5s;
  flex-grow: 1;
  .slick-slide {
    outline: none;
  }
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }
`

export const Slide = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  outline: none;
  .youtubeContainer {
    width: 100%;
    height: 100%;
    iFrame {
      width: 100%;
      height: 100%;
    }
  }
`

export const imageStyle = css`
  max-width: 100%;
  max-height: calc(100vh - ${2 * topBottomHeight}px);
  position: absolute;
  ${center};
`

export const CloseButtonPosition = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`

export const Background = styled.div`
  ${fullSizeAndAbsolute};
  background-color: ${colors.blue.dark}ef;
  pointer-events: none;
`

export const BottomNav = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  ${centerH};
`

export const MediaLayerContainer = styled.div`
  ${fullSizeAndAbsolute};
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  transition: opacity 0.5s;
  user-select: none;
  ${props =>
    !props.open
      ? css`
          opacity: 0;
          pointer-events: none;
          ${Top} {
            transform: translateY(-100%);
          }
          ${Bottom} {
            transform: translateY(100%);
          }
          ${Middle} {
            transform: scale(1.2);
            opacity: 0;
          }
        `
      : css`
          ${CloseButtonPosition}, ${BottomNav}, ${Slide} > img, ${Background}, ${Slide} .youtubeContainer {
            pointer-events: all;
          }
          ${Slide} > img {
            user-select: all;
          }
        `}
`
