import styled from 'styled-components'
import easing from '@/styles/easing'
import { colors } from '@/styles/color'

export const SocialLinksContainer = styled.div`
  position: absolute;
  right: 0;
`

export const SocialLink = styled.a`
  margin-left: 10px;
  display: inline-block;
  transition: transform 0.15s ${easing.inOutStrong};
  svg {
    circle,
    path {
      transition: fill 0.15s;
    }
  }
  &:hover {
    transform: scale(1.15);
    svg {
      circle {
        fill: ${colors.blue.dark};
      }
      path {
        fill: ${colors.moss.light};
      }
    }
  }
`
