import { useMediaLayerStore } from '@/service/MediaLayerService'
import {
  MediaLayerContainer,
  Top,
  Middle,
  Bottom,
  CloseButtonPosition,
  Slide,
  imageStyle,
  PaginationContainer,
  Background,
  BottomNav,
  nextPrevButtonStyle,
} from './styles'
import CloseButton from '@/components/common/CloseButton'
import Slider from 'react-slick'
import { useEffect, useMemo, useRef } from 'react'
import Image from '@/components/common/Image'
import Pagination from '@/components/common/Pagination'
import { paginationStyle } from '@/components/Pages/Page/Modules/BigMediaSlider/styles'
import NextPrevButton from '@/components/common/NextPrevButton'
import YouTube from 'react-youtube'
import { colors } from '@/styles/color'

const MediaLayer = () => {
  const sliderRef = useRef()
  const media = useMediaLayerStore(state => state.media)
  const activeIndex = useMediaLayerStore(state => state.activeIndex)
  const { selectMedium, closeMedia, selectPrevMedium, selectNextMedium, setInTransition } = useMediaLayerStore()

  const paginationItems = useMemo(() => {
    return media.map((medium, index) => {
      return { title: index + 1, active: index === activeIndex, itemId: index }
    })
  }, [activeIndex, media])

  useEffect(() => {
    if (sliderRef.current) sliderRef.current.slickGoTo(activeIndex)
  }, [activeIndex])

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
    beforeChange: (current, next) => {
      selectMedium(next)
    },
    afterChange: () => {
      setInTransition(false)
    },
  }

  const youtubeOptions = {
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      autoplay: 1,
      color: 'white',
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  }

  const open = media.length > 0
  return (
    <MediaLayerContainer open={open}>
      <Background onClick={closeMedia} />
      <Top>
        <CloseButtonPosition>
          <CloseButton clickHandler={closeMedia} />
        </CloseButtonPosition>
      </Top>
      <Middle>
        {open && (
          <Slider ref={sliderRef} {...sliderSettings}>
            {media.map((medium, index) => {
              const { youtubeId, image } = medium
              return (
                <Slide key={index}>
                  {!!image && !youtubeId && <Image image={image} desktopSizeFactor={1} extraStyle={imageStyle} />}
                  {!!youtubeId && (
                    <YouTube containerClassName={'youtubeContainer'} videoId={youtubeId} opts={youtubeOptions} />
                  )}
                </Slide>
              )
            })}
          </Slider>
        )}
      </Middle>
      <Bottom>
        {paginationItems.length > 1 && (
          <BottomNav>
            <NextPrevButton prev extraStyle={nextPrevButtonStyle} clickHandler={selectPrevMedium} />
            <PaginationContainer>
              <Pagination
                horizontal
                hideLabels
                extraStyle={paginationStyle}
                selectHandler={selectMedium}
                items={paginationItems}
              />
            </PaginationContainer>
            <NextPrevButton next extraStyle={nextPrevButtonStyle} clickHandler={selectNextMedium} />
          </BottomNav>
        )}
      </Bottom>
    </MediaLayerContainer>
  )
}

export default MediaLayer
