import PlausibleProvider from 'next-plausible'
import { RUNTIME_ENVIRONMENT_NAME } from '../cms'

const isProduction = RUNTIME_ENVIRONMENT_NAME === 'production'

export const Plausible = ({ children }) => {
  const domain = isProduction ? 'des.de' : 'develop.new.des.de'

  return (
    <PlausibleProvider domain={domain} customDomain={'https://plausible.des.de'} selfHosted={true}>
      {children}
    </PlausibleProvider>
  )
}
