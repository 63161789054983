import {RUNTIME_ENVIRONMENT_NAME} from '../cms'

const isProduction = RUNTIME_ENVIRONMENT_NAME === 'production'

export const TagManager = () => {
    return (
        <>
            {isProduction && (
                <>
                    <script type="text/javascript" dangerouslySetInnerHTML={{
                        __html: `
            // create dataLayer
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
           }

            // set „denied" as default for both ad and analytics storage, as well as ad_user_data and ad_personalization,
            gtag("consent", "default", {
                ad_user_data: "denied",
                ad_personalization: "denied",
                ad_storage: "denied",
                analytics_storage: "denied",
                wait_for_update: 2000 // milliseconds to wait for update
            });

            // Enable ads data redaction by default [optional]
            gtag("set", "ads_data_redaction", true);

            // Google Tag Manager
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MCNJGLM');`
                    }}
                    />
                </>
            )}
        </>
    )
}
export const ownJS = () => {
    return (
        <>
            {isProduction && (
                <script src="/des.js"></script>
            )}
        </>
    )
}

export const TagManagerNoScript = () => {
    return (
        <>
            {isProduction && (
                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCNJGLM"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                    }}
                ></noscript>
            )}
        </>
    )
}
