import { useEffect, useState } from 'react'
import {
  loaderHeadline,
  HeadlinePlacer,
  SeoContentExperience,
  StyledFlexWrapperLoader,
  StyledLoader,
  StyledLoaderContent,
  StyledPlacer,
  StyledSVGLogo,
  StyledSVGLogoAnimated,
} from '@/components/Scene/Intro/styles'

import { useSceneStore } from '@/service/SceneService'
import BackgroundGrid from '@/components/common/BackgroundGrid'
// import {Headline1, Headline2, Headline3} from "@/components/Pages/Page/Modules/LongText/styles";
import Markdown from 'markdown-to-jsx'
import {pseudoH2} from "@/styles/text";

export default function Intro({ loadingText, startPageSeoText }) {
  const [loaded, setLoaded] = useState(false)
  const [finished, setFinished] = useState(false)

  const isLoaded = useSceneStore(state => state.isLoaded)

  useEffect(() => {
    if (isLoaded && !loaded) {
      setLoaded(true)
      setTimeout(() => {
        setFinished(true)
      }, 1500)
    }
  }, [isLoaded])

  return (
    !finished && (
      <StyledLoader animationFinished={loaded}>
        <StyledLoaderContent>
          <StyledFlexWrapperLoader>
            <StyledPlacer>
              <StyledSVGLogo />
            </StyledPlacer>
            <StyledPlacer>
              <StyledSVGLogoAnimated />
            </StyledPlacer>
          </StyledFlexWrapperLoader>
          <HeadlinePlacer className="pseudoH3">
            <loaderHeadline>{loadingText}</loaderHeadline>
          </HeadlinePlacer>
          <SeoContentExperience>
            {startPageSeoText && (
            <Markdown
                options={{
                  forceBlock: true,
                  /*overrides: {
                    h1: Headline1,
                    h2: Headline2,
                    h3: Headline3,
                  },*/
                }}
            >
              {startPageSeoText || ''}
            </Markdown>
            )}
          </SeoContentExperience>
        </StyledLoaderContent>
        <BackgroundGrid unlimitedWidth withoutAnimation />
      </StyledLoader>
    )
  )
}
