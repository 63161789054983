import {
  FooterContainer,
  FooterContent,
  FooterColumn,
  FooterRow,
  LegalFooterRow,
  ColumnTitle,
  P,
  FooterButton,
  FooterLink,
  BackToTopButtonContainer,
  arrowButtonStyle,
} from './styles'
import FooterBackground from './FooterBackground'
import MaxWidth from '@/components/common/MaxWidth'
import ArrowButton from '@/components/common/ArrowButton'
import Linky, { getPageLikeFromLinkObject } from '@/components/common/Linky'

const renderFooterElements = (modules, underline = false, inline = false) => {
  return modules.map((module, mIndex) => {
    const LinkComponent = module.isButton ? FooterButton : FooterLink
    const pageLike = getPageLikeFromLinkObject(module)
    switch (module['__component']) {
      case 'footer.footer-external-link':
        return (
          <Linky key={mIndex} to={{ externalUrl: module.url }}>
            <LinkComponent underline={underline} inline={inline}>
              {module.text}
            </LinkComponent>
          </Linky>
        )
      case 'footer.footer-page-link':
        if (!pageLike) return null
        return (
          <Linky key={mIndex} to={pageLike}>
            <LinkComponent underline={underline} inline={inline}>
              {module.titleOverwrite
                ? module.titleOverwrite
                : pageLike.shortTitle
                ? pageLike.shortTitle
                : pageLike.title}
            </LinkComponent>
          </Linky>
        )
      case 'footer.footer-copy':
        return <P key={mIndex}>{module.copy}</P>
    }
  })
}

const Footer = ({ footer }) => {
  return (
    <FooterContainer>
      <FooterBackground />
      <BackToTopButtonContainer>
        <ArrowButton
          clickHandler={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          direction="up"
          extraStyle={arrowButtonStyle}
        />
      </BackToTopButtonContainer>
      <FooterContent>
        <MaxWidth>
          <FooterRow>
            {footer.columns.map((column, cIndex) => (
              <FooterColumn key={cIndex}>
                <ColumnTitle>{column.title}</ColumnTitle>
                {renderFooterElements(column.modules)}
              </FooterColumn>
            ))}
          </FooterRow>
          <LegalFooterRow>
            <FooterColumn>© 2023 DES GmbH</FooterColumn>
            <FooterColumn>{renderFooterElements(footer.legalLinks, true, true)}</FooterColumn>
          </LegalFooterRow>
        </MaxWidth>
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer
