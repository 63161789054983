import { useState } from 'react'
import ArrowButton from '@/components/common/ArrowButton'
import { Container, TextContainer } from './styles'

const ArrowButtonWithText = ({
  text,
  clickHandler = () => {},
  direction = 'left',
  transparent = false,
  isOnWhite = false,
}) => {
  const [hover, setHover] = useState(false)
  return (
    <Container onClick={clickHandler} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <ArrowButton transparent={transparent} direction={direction} hoverOverwrite={hover} />
      <TextContainer isOnWhite={isOnWhite}>{text}</TextContainer>
    </Container>
  )
}

export default ArrowButtonWithText
