import SiteLayout from '@/components/SiteLayout'
import { GlobalStyle } from '@/styles/global'
import { Plausible } from '@/utils/tracking/Plausible'
import React, { useEffect } from 'react'
import { useEffectStore } from '@/service/EffectService'
import { useMediaLayerStore } from '@/service/MediaLayerService'

function App({ Component, pageProps }) {
  useEffect(() => {
    useEffectStore.getState().init()
  }, [])
  const mediaLayerOpen = useMediaLayerStore(state => state.media.length > 0)
  const { uniqueId, experience } = Component
  if (pageProps.error) {
    return (
      <div>
        <GlobalStyle />
        <h1>Oops! Something went wrong.</h1>
        <div>{JSON.stringify(pageProps.error)}</div>
      </div>
    )
  }
  return (
    <Plausible>
      <SiteLayout pageProps={pageProps} uniqueId={uniqueId} experience={!!experience}>
        <GlobalStyle experience={!!experience} preventScroll={mediaLayerOpen} />
        <Component {...pageProps} />
      </SiteLayout>
    </Plausible>
  )
}

export default App
