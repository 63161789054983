import styled, { css } from 'styled-components'
import { colors } from '@/styles/color'
import { neueMachinaFontStyle } from '@/styles/font'

export const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${colors.white};
`

export const TextContainer = styled.div`
  display: inline-block;
  line-height: 26px;
  vertical-align: top;
  margin-left: 10px;
  ${neueMachinaFontStyle};
  ${props =>
    props.isOnWhite &&
    css`
      color: ${colors.moss.base};
    `};
`
