import styled, { css } from 'styled-components'

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${props =>
    props.prev &&
    css`
      left: 0;
    `};
  ${props =>
    props.next &&
    css`
      right: 0;
    `};
  svg {
    position: absolute;
    top: 5px;
    left: 8px;
    ${props =>
      props.next &&
      css`
        transform: rotate(180deg);
      `};
  }
  ${props => props.extraStyle};
`
