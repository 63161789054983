import create from 'zustand'

export const useSceneStore = create(() => ({
  world: null,
  isLoaded: false,
  hasMaterials: false,
  patchedReveal: false,
  patchedGround: false,
  experienceData: null,
}))
