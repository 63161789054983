import { BlurryBackgroundContainer, BlurBall } from './styles'

const BlurryBackgroundSimple = () => {
  return (
    <BlurryBackgroundContainer>
      <BlurBall index={1} />
      <BlurBall index={2} />
      <BlurBall index={3} />
    </BlurryBackgroundContainer>
  )
}

export default BlurryBackgroundSimple
