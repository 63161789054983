import styled, { css, keyframes } from 'styled-components'
import { colors } from '@/styles/color'
import des_svg from '@/components/Scene/Intro/des.svg'
import { neueMachinaFontStyle } from '@/styles/font'
import { mediaMax } from '@/styles/layout'

export const StyledLoader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: #090c13;
  opacity: ${props => (props.animationFinished ? 0 : 1)};
  transition: opacity 0.5s cubic-bezier(0.76, 0.01, 0.74, 0.94);
  user-select: none;
  overflow-x: hidden;
  overflow-y: scroll;
`

export const StyledLoaderContent = styled.div`
  width: 90%;
  min-width: 250px;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: 0;
`

export const StyledFlexWrapperLoader = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  position: relative;
  height: 100vh;
`

export const StyledPlacer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: 400px;
`

const appear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const HeadlinePlacer = styled.div`
  position: absolute;
  width: 100%;
  top: calc(100vh - 10%);
  left: 0;

  ${mediaMax(
    'medium',
    css`
      top: calc(100vh - 5%);
    `
  )};
`

export const SeoContentExperience = styled.div`
  position: relative;
  padding: 5rem 0;
    max-width: 550px;
    margin: 0 auto;
    
  ${mediaMax(
    'medium',
    css`
      padding: 3rem 0;
    `
  )};
`

export const loaderHeadline = styled.div`
  ${neueMachinaFontStyle};
  font-weight: normal;
  font-size: 1.5em;
  opacity: 0;
  animation: ${appear} 0.2s 0s ease-in-out forwards;

  ${mediaMax(
    'small',
    css`
      font-size: 1em;
    `
  )};
`

const strokeStyle = css`
  stroke: ${colors.moss.dark};
`

export const StyledSVGLogo = styled(des_svg)`
  animation: ${appear} 0.2s 0s ease-in-out forwards;
  /* transform: translate3d(0, 50%, 0); */

  & .d {
    ${strokeStyle}
  }

  & .e1 {
    ${strokeStyle}
  }

  & .e2 {
    ${strokeStyle}
  }

  & .e3 {
    ${strokeStyle}
  }

  & .s {
    ${strokeStyle}
  }
`
export const StyledSVGLogoAnimated = styled(des_svg)`
  filter: drop-shadow(0 0 10px #53d4a0);
  /* transform: translate3d(0, 50%, 0); */

  & .d {
    stroke-dasharray: 184%;
    stroke-dashoffset: 184%;
    animation: dIn 1s ease-out 0.2s forwards;
  }

  & .e1 {
    stroke-dasharray: 82%;
    stroke-dashoffset: 82%;
    animation: eIn 0.6s ease-out 0.2s forwards;
  }

  & .e2 {
    stroke-dasharray: 82%;
    stroke-dashoffset: 82%;
    animation: eIn 0.6s ease-out 0.4s forwards;
  }

  & .e3 {
    stroke-dasharray: 82%;
    stroke-dashoffset: 82%;
    animation: eIn 0.6s ease-out 0.6s forwards;
  }

  & .s {
    stroke-dasharray: 259%;
    stroke-dashoffset: 259%;
    animation: sIn 1s ease-out 0.2s forwards;
  }

  @keyframes dIn {
    0% {
      stroke-dashoffset: 184%;
    }
    100% {
      stroke-dashoffset: 0%;
    }
  }

  @keyframes eIn {
    0% {
      stroke-dashoffset: 82%;
    }
    100% {
      stroke-dashoffset: 0%;
    }
  }

  @keyframes sIn {
    0% {
      stroke-dashoffset: 259%;
    }
    100% {
      stroke-dashoffset: 0%;
    }
  }
`
