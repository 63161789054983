import { BurgerContainer, Line, XLineContainer, XLine } from './styles'
import { useMenuStore } from '@/service/MenuService'

const BurgerButton = ({ safeZone, headerGap, active = true }) => {
  const open = useMenuStore(state => state.open)
  const toggleMenu = useMenuStore(state => state.toggleMenu)
  return (
    <BurgerContainer showX={open} onClick={toggleMenu} active={active} safeZone={safeZone} headerGap={headerGap}>
      <Line />
      <XLineContainer>
        <XLine />
        <XLine />
      </XLineContainer>
      <Line short />
    </BurgerContainer>
  )
}

export default BurgerButton
