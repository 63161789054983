import styled, { css } from 'styled-components'
import { column, offsetRight, padding, row } from '@/styles/grid'
import {H5, pseudoH5} from '@/styles/text'
import { colors } from '@/styles/color'
import { neueMachinaFontStyle } from '@/styles/font'
import Button from '@/components/common/Button'
import { extraSmall, mediaMax, mediaMin, medium, small } from '@/styles/layout'

export const FooterContainer = styled.div`
  padding: 120px 0 50px;
  position: relative;
  user-select: auto;
  z-index: 1;
  ${mediaMax(
    'small',
    css`
      padding-top: 0;
    `
  )}
`

export const BackToTopButtonContainer = styled.div`
  position: absolute;
  width: 20%;
  top: 0;
  transform: translateY(-100%);
`

export const arrowButtonStyle = css`
  display: block;
  float: right;
`

export const FooterContent = styled.div`
  position: relative;
  ${mediaMax(
    'medium',
    css`
      font-size: 14px;
    `
  )}
`

export const FooterColumn = styled.div`
  ${column(2, 0)};
  ${padding};
  ${mediaMin(
    'medium',
    css`
      &:first-of-type {
        ${column(2, 2)};
      }
    `
  )}
  ${small(css`
    ${column(8, 1)};
    ${offsetRight(3)}
  `)}
  ${extraSmall(css`
    ${column(10, 1)};
  `)}
`

export const ColumnTitle = styled(pseudoH5)`
  color: ${colors.grey.base};
  padding-right: 25px;
  ${mediaMax(
    'small',
    css`
      margin-block-start: 3em;
    `
  )}
`

const inlineStyle = css`
  margin-right: 30px;
  display: inline-block;
`

export const FooterLink = styled.a`
  display: block;
  margin-bottom: 15px;
  ${neueMachinaFontStyle};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  color: ${colors.white};
  ${props => props.inline && inlineStyle}
`

export const FooterButton = styled(Button)`
  min-width: 0;
  text-decoration: none;
  ${props => props.inline && inlineStyle}
  ${medium(css`
    padding-left: 12px;
    padding-right: 12px;
  `)}
`

export const P = styled.p`
  padding-right: 25px;
  margin-bottom: 25px;
`

export const FooterRow = styled.div`
  ${row};
  &:first-of-type {
    margin-bottom: 100px;
  }
`

export const LegalFooterRow = styled(FooterRow)`
  ${mediaMax(
    'medium',
    css`
      font-size: 12px;
    `
  )};
  ${mediaMax(
    'small',
    css`
      ${column(10, 1)};
      ${FooterColumn} {
        flex-basis: auto;
        margin: 0;
      }
    `
  )};
  ${medium(css`
    ${FooterColumn} {
      ${offsetRight(0)}
    }
  `)};
`
