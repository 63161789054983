import { FooterBackgroundContainer } from './styles'
import BackgroundGrid from '@/components/common/BackgroundGrid'
import BlurryBackgroundSimple from '@/components/common/BlurryBackgroundSimple'

const FooterBackground = () => {
  return (
    <FooterBackgroundContainer>
      <BackgroundGrid inner Background={BlurryBackgroundSimple} opacity={0.5} length={1} />
    </FooterBackgroundContainer>
  )
}

export default FooterBackground
