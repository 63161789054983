import styled, { css, keyframes } from 'styled-components'
import { fullSizeAndAbsolute, mediaMax, medium } from '@/styles/layout'
import easing from '@/styles/easing'
import { colors } from '@/styles/color'

export const BlurryBackgroundContainer = styled.div`
  ${fullSizeAndAbsolute};
  background-color: ${colors.blue.dark};
  overflow: hidden;
`

const swirl = keyframes`
  from {
    opacity: 0.4;
    transform: scale3d(1.6, 1.85, 1) rotate(-30deg) translate3d(12%, -12%, 0);
  }
  to {
    opacity: 0.6;
    transform: scale3d(1.95, 1.55, 1) rotate(30deg) translate3d(-12%, 12%, 0);
  }
`

const ballStyle = index =>
  index === 1
    ? css`
        top: -14%;
        left: -16%;
        ${medium(
          css`
            width: max(51vw, 51vh);
            height: max(53vw, 53vh);
          `
        )};
        ${mediaMax(
          'small',
          css`
            width: max(60vw, 60vh);
            height: max(68vw, 68vh);
          `
        )};
      `
    : index === 2
    ? css`
        right: min(-15%, -150px);
        top: -13%;
        width: max(45vw, 55vh);
        height: max(33vw, 43vh);
        ${mediaMax(
          'small',
          css`
            width: max(55vw, 55vh);
            height: max(57vw, 57vh);
          `
        )};
      `
    : index === 3
    ? css`
        bottom: -42%;
        left: 19%;
        width: max(49vw, 64vh);
        height: max(49vw, 64vh);
      `
    : null

export const BlurBall = styled.div`
  width: max(40vw, 40vh);
  height: max(45vw, 45vh);
  //background-color: aqua;
  position: absolute;
  animation: ${swirl} 2s alternate infinite ${easing.inOutStrong};
  animation-fill-mode: forwards;
  animation-duration: ${props => 64 + 10 / props.index}s;
  animation-delay: -${props => props.index * 16}s;
  background-image: url('https://d17mhzg4etm7z2.cloudfront.net/website/blur.png');
  background-size: 100% 100%;
  ${props => ballStyle(props.index)}
`
